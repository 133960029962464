<template>
  <div id="products_listing">
    <div class="content-grid">
<!--      <div class="content-small mb-3">-->
<!--        <travel-search @search="submitSearch" standalone="0"></travel-search>-->
<!--      </div>-->
      <div class="content-wide">
        <product-listing-header :title="title" :description="top_description"></product-listing-header>
        <product-filters :filters="filters"
                         :productCount="productCount" :loading="loading"
                         v-on:filters:submit="submitFilters"></product-filters>
        <products :products="products" :loading="loading"></products>

        <div class="bottom-description-wrp" v-if="bottom_description">
          <div class="description" v-html="bottom_description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {travel} from "./reactives/travel";
import {destinations} from "./reactives/destinations";
import {selectedFilters} from "./reactives/selectedFilters";
import {sort} from "./reactives/sort";
import moment from 'moment';

export default {
  props: ['title', 'top_description', 'bottom_description', 'brand', 'collection', 'category'],
  data() {
    return {
      travel,
      destinations,
      sort,
      selectedFilters,
      products: [],
      filters: {},
      operators: window.operators,
      filter: [],
      processes: [],
      loading: true,
      activeSearches: 0,
      endReached: false,
      checkInProgress: false,
      loadCount: 0,
      total: 0,
      pageSize: 8,
      page: 1,
      productCount: 0,
      initial: true,
    }
  },
  watch: {
    activeSearches(newVal, oldVal) {
      if (oldVal > 0 && newVal < 1) {
        this.loading = false;
        this.productCount = Object.keys(this.products).length;
        if(this.initial) {
          this.initial =false;
          this.initialFilter();
        }
        this.sortProducts();
      }
    },
    'sort.text': function (val) {
      this.sortProducts();
    },
    'travel.search' : function(newVal, oldVal) {
      if(newVal && !oldVal) {
        this.performLoad();
      }
    }
  },
  mounted() {
    let component = this;
    if (typeof window.travel !== 'undefined' && Object.keys(window.travel).length > 0) {
      Object.keys(window.travel).forEach(function (index) {
        if(index === 'date') {
          if(typeof window.travel[index]['from'] !== 'undefined') {
            component.travel[index] = window.travel[index];
          } else {
            component.travel[index]['from'] = window.travel[index];
            component.travel[index]['to'] = window.travel[index];
          }
        } else {
          component.travel[index] = window.travel[index];
        }
      });
      if(component.travel['date']['from'].length > 0) {
        if(component.travel['tab'] === 'month') {
          moment.locale('lt');
          component.travel.activeDateText = moment(component.travel['date']['from']).format('YYYY MMMM');
          if(moment(component.travel['date']['from']).format('MM') !== moment(component.travel['date']['to']).format('MM')) {
            component.travel.activeDateText += ' - ' +  moment(component.travel['date']['to']).format('YYYY MMMM');
          }
        } else {
          component.travel.activeDateText = moment(component.travel['date']['from']).format('YYYY-MM-DD');
          if(component.travel['date']['from'] !== component.travel['date']['to']) {
            component.travel.activeDateText += ' - ' +  moment(component.travel['date']['to']).format('YYYY-MM-DD');
          }
        }
      }
    }
    this.performLoad();
    setTimeout(function () {
      component.checkProcesses();
    }, 1000);
    this.infiniteScroll();
  },
  methods: {
    initialFilter() {
      let component = this;
      if (typeof window.selectedFilters !== 'undefined' && Object.keys(window.selectedFilters).length > 0) {
        Object.keys(window.selectedFilters).forEach(function (type) {
          component.selectedFilters[type] = window.selectedFilters[type];
          Object.keys(component.selectedFilters[type]).forEach(function(filter_id) {
            Object.keys(component.selectedFilters[type][filter_id]).forEach(function(value_id) {
              if(typeof component.filters[filter_id] !== 'undefined') {
                let found = false;
                component.filters[filter_id].filter_items.forEach(function(item,key) {
                  if(parseInt(item.id) === parseInt(value_id)) {
                    found = true;
                    component.selectedFilters[type][filter_id][value_id] = key;
                  }
                });
                if(!found) {
                  delete component.selectedFilters[type][filter_id][value_id];
                }
              }
            });
          });
        });
      }
      this.submitFilters();
    },
    performLoad() {
      let component = this;
      if(!this.travel.loaded) {
        setTimeout(function () {
          component.performLoad();
        }, 300);
      } else {
        component.travel.search = false;
        component.getProducts();
      }
    },
    mergeFilters(filters) {
      let component = this;
      Object.keys(filters).forEach(function (key) {
        if (typeof component.filters[key] === "undefined") {
          component.filters[key] = JSON.parse(JSON.stringify(filters[key]));
          if (typeof component.selectedFilters[component.filters[key].type] === "undefined") {
            component.selectedFilters[component.filters[key].type] = {}
          }
          if (typeof component.selectedFilters[component.filters[key].type][key] === "undefined") {
            component.selectedFilters[component.filters[key].type][key] = {};
          }
        } else {
          if (typeof filters[key].filter_items !== "undefined") {
            filters[key].filter_items.forEach(function (item) {
              let found = false;
              component.filters[key].filter_items.forEach(function(existing) {
                if(existing.id === item.id) {
                  found = true;
                }
              });
              if(found) {
                return;
              }
              component.filters[key].filter_items.push(JSON.parse(JSON.stringify(item)));
            });
          }
        }
      });
    },
    mergeProducts(products) {
      let component = this;
      let ids = [];
      let count = 0;
      component.products.forEach(function (p) {
        ids.push(p.product_id);
      });
      products.forEach(function (prod) {
        prod.filtered = true;
        prod.visible = ++count<=4;
        let index = ids.indexOf(prod.product_id);
        if (index >= 0) {
          if (component.products[index].price > prod.price) {
            component.products[index] = JSON.parse(JSON.stringify(prod));
          }
        } else {
          component.products.push(JSON.parse(JSON.stringify(prod)));
        }
      });
      this.productCount = Object.keys(this.products).length;
    },
    checkProcesses() {
      let component = this;
      setTimeout(function () {
        component.checkProcesses();
      }, 1000);
      if(component.checkInProgress) {
        return;
      }
      component.checkInProgress = true;
      if (component.processes.length > 0) {
        for (let [index, process] of component.processes.entries()) {
          if (component.processes[index].status === 'done') {
            component.processes.splice(index, 1);
          }
        }
        ;
        component.processes.forEach(function (process, index) {
          if (process.status === 'new') {
            process.status = 'checking';
            $.ajax({
              type: 'GET',
              url: '?display=content_types/products/ajax_products.tpl',
              data: {
                operator: process.operator,
                filters: component.selectedFilters,
                travel: component.travel.prepareForRequest(Object.keys(component.destinations.activeCategories),Object.keys(component.destinations.activeHotels)),
                collection: component.collection,
                category: component.category,
                pid: process.pid
              },
              success: function (data) {
                if (typeof data.background !== "undefined") {
                  process.status = 'new';
                } else {
                  process.status = 'done';
                  if(typeof data.products !== "undefined" && data.products.length > 0) {
                    component.mergeProducts(data.products);
                    component.operators[process.operatorIndex].max += data.max;
                    component.mergeFilters(data.filters.filter_data);
                  }
                  component.activeSearches--;
                }
              }
            });
          }
        });
      }
      component.checkInProgress = false;
    },
    getProducts(loadMore = false) {
      let component = this;
      component.products = [];
      component.filters = {};
      component.loading = true;
      if(!this.initial) {
        history.pushState(
            {},
            null,
            '?' + decodeURIComponent($.param({
              filters: component.selectedFilters,
              sort: component.sort.text,
              travel: component.travel.prepareForRequest(Object.keys(component.destinations.activeCategories), Object.keys(component.destinations.activeHotels))
            }))
        )
      }
      let loadStarted = false;
      this.operators.forEach(function (operator, key) {
        if (component.brand.length > 0 && component.brand !== operator.id) {
          return;
        }
        if (!loadMore) {
          component.operators[key].page = 1;
          delete component.operators[key].max;
        } else {
          component.operators[key].page++;
        }
        if (typeof component.operators[key].max !== "undefined" && component.operators[key].page > component.operators[key].max) {
          return;
        }
        loadStarted = true;
        component.activeSearches++;

        $.ajax({
          type: 'GET',
          url: '?display=content_types/products/ajax_products.tpl',
          data: {
            operator: operator.id,
            filters: component.selectedFilters,
            travel: component.travel.prepareForRequest(Object.keys(component.destinations.activeCategories),Object.keys(component.destinations.activeHotels)),
            collection: component.collection,
            category: component.category,
            page: component.operators[key].page
          },
          success: function (data) {
            if (typeof data.background !== "undefined") {
              component.activeSearches--;
              data.pids.forEach(function (pid) {
                component.activeSearches++;
                component.processes.push({
                  'pid': pid,
                  'operator': operator.id,
                  'operatorIndex': key,
                  'status': 'new'
                });
              });
            } else {
              component.mergeProducts(data.products);
              component.operators[key].max += data.max;
              component.mergeFilters(data.filters.filter_data);
              component.activeSearches--;
            }
          }
        });
      });
    },
    submitFilters() {
      let component = this;
      let systemFilters = {};
      let priceFilter = [];
      history.pushState(
          {},
          null,
          '?' + decodeURIComponent($.param({
            filters: component.selectedFilters,
            sort: component.sort.text,
            travel: component.travel.prepareForRequest(Object.keys(component.destinations.activeCategories),Object.keys(component.destinations.activeHotels))
          }))
      )
      Object.keys(component.selectedFilters).forEach(function(type) {
        if(type === 'pansions' || type === 'stars') {
          let field = type;
          if(type === 'pansions') {
            field = 'pansion';
          }
          let vals = [];
          Object.keys(component.selectedFilters[type]).forEach(function(filter_id) {
            vals = vals.concat(Object.keys(component.selectedFilters[type][filter_id]));
          });
          if(vals.length > 0) {
            systemFilters[field] = vals
          }
        }
        if(type === 'prices') {
          let ranges = [];
          Object.keys(component.selectedFilters[type]).forEach(function(filter_id) {
            component.filters[filter_id].filter_items.forEach(function(item) {
              if(Object.keys(component.selectedFilters[type][filter_id]).includes(item.id.toString())) {
                ranges.push(item.range);
              }
            });
          });
          if(ranges.length > 0) {
            priceFilter = ranges;
          }
        }
        if(type === 'category_tree') {

        }
      });
      this.products.forEach(function(prod) {
        prod.filtered = true;
            if(typeof component.selectedFilters['checkboxes'] !== 'undefined') {
              Object.keys(component.selectedFilters['checkboxes']).forEach(function (filter_id) {
                if (Object.keys(component.selectedFilters['checkboxes'][filter_id]).length > 0) {
                  if (typeof prod['filter_values_ids'] !== 'undefined') {
                    let intersection = prod['filter_values_ids'].filter(value => Object.keys(component.selectedFilters['checkboxes'][filter_id]).includes(value))
                    if (intersection.length === 0) {
                      prod.filtered = false;
                    }
                  } else {
                    prod.filtered = false;
                  }
                }
              });
            }
        if(typeof component.selectedFilters['category_tree'] !== 'undefined') {
          Object.keys(component.selectedFilters['category_tree']).forEach(function (filter_id) {
            if (Object.keys(component.selectedFilters['category_tree'][filter_id]).length > 0) {
              if (!Object.keys(component.selectedFilters['category_tree'][filter_id]).includes(prod['city']) && !Object.keys(component.selectedFilters['category_tree'][filter_id]).includes(prod['country'])) {
                prod.filtered = false;
              }
            }
          });
        }
        Object.keys(systemFilters).forEach(function(field) {
          if(!systemFilters[field].includes(prod[field])) {
            prod.filtered = false;
          }
        });
       if(priceFilter.length > 0) {
         let found = 0;
         priceFilter.forEach(function(range) {
           if(typeof range[1] !== 'undefined') {
             if(prod.price >= range[0] && prod.price <= range[1]) {
               found = true;
             }
           } else {
             if(prod.price >= range[0]) {
               found = true;
             }
           }
         });
         if(!found) {
           prod.filtered = false
         }
       }
      });
      let count = 0
      this.products.forEach(function(prod) {
        if(prod.filtered) {
          count++;
        }
      });
      this.productCount = count;
      this.displayProducts();
      //this.getProducts();
    },
    sortProducts() {
      let component = this;
      component.products.sort(function (a, b) {
        if (['price', 'position','discount'].includes(component.sort.field)) {
          return component.sort.direction === 'asc' ? a[component.sort.field] - b[component.sort.field] : b[component.sort.field] - a[component.sort.field];
        } else {
          if (['date'].includes(component.sort.field)) {
            return component.sort.direction === 'asc' ? moment(a[component.sort.field]).diff(moment(b[component.sort.field])) : moment(b[component.sort.field]).diff(moment(a[component.sort.field]));
          } else {
            return component.sort.direction === 'asc' ? a[component.sort.field] - b[component.sort.field] : b[component.sort.field] - a[component.sort.field];
          }
        }
      });
      this.displayProducts();
    },
    displayProducts() {
      let count = 0;
      let component = this;
      component.products.forEach(function(prod) {
          if(prod.filtered) {
            prod.visible = ++count <= (component.pageSize * component.page)
          }
      });
      component.endReached = component.products.length < component.pageSize * component.page;
    },
    infiniteScroll() {
      let component = this;
      const listing = $('#products-list');
      document.addEventListener('scroll', e => {
        var full_height = listing.height();
        var window_height = $(window).height();
        var scrollTop =$(window).scrollTop();
        if (!component.loading && !component.endReached && scrollTop + window_height >= full_height - 700) {
          component.page++;
          component.displayProducts();
        }
      });
    },
  }
}
</script>
