<template>
  <div class="filters-sort-wrp">
    <button class="btn btn-secondary mobile-filter-toggle-btn pf-toggle-menu" @click="toggleFilters">
      {{t('Filtras')}} <span>{{ filtersCount }}</span>
    </button>

    <div id="filter" :class="{'is_shown':showFilters}">
      <div class="filter-header">
        <span class="filter-title">{{ t('Filtras') }} ({{filtersCount}})</span>
        <button class="btn-close act-close-filters" type="button" aria-label="Close" @click="toggleFilters"></button>
      </div>

      <div class="filters-listing">
        <div v-if="loading" class="filters-loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div v-if="!loading" v-for="filter in filters" class="filter-group-wrp ajax" :class="{'open':openedFilter===filter.id}"
             v-clickOutside:[filter.id]="closeFilter">
          <template v-if="['categories','brands'].includes(filter.type)">
            <!--            <component v-if="filter.type" :is="'filter_'+filter.type" :filter="filter" @selectFilterItem="selectFilterItem"></component>-->
          </template>
          <template v-else-if="['category_tree'].includes(filter.type)">
            <h3 class="filter-group-title" @click="openFilter(filter.id)">{{ filter.name }}</h3>
            <div class="filter-group-items">
              <ul class="inner-wrp custom-scrollbar">
                <template v-for="(filter_item,key) in filter.filter_items">
                  <li class="widget-filter-item"
                      v-if="typeof selectedFilters[filter.type]!=='undefined'"
                      :class="{'open':categoryOpened === filter_item.id, active:typeof selectedFilters[filter.type]!=='undefined'&& typeof selectedFilters[filter.type][filter.id][filter_item.id]!=='undefined'&&typeof selectedFilters[filter.type][filter.id][filter_item.id]==='1'}"
                  >
                    <div class="filter-check mb-0">
                      <label class="form-check-label widget-filter-item-text" :for="filter.id+'_'+filter_item.id">
                        <input class="form-check-input" type="checkbox" :id="filter.id+'_'+filter_item.id"
                               @change="categoryChange($event,filter.type,filter.id,filter_item.id,key)"
                               :checked="typeof selectedFilters[filter.type][filter.id][filter_item.id]!=='undefined'"
                        >
                        {{ filter_item.name }}
                      </label>
                      <span class="childs-indicator" @click="toggleShowChildren(filter_item.id)"
                            v-show="Object.keys(filter_item.childs).length > 0">
                        <i class="fa-regular fa-chevron-down"></i>
                      </span>
                    </div>
                  </li>
                  <li v-for="(child_item,child_key) in filter_item.childs" class="widget-filter-item child"
                      v-show="categoryOpened === filter_item.id"
                      v-if="typeof selectedFilters[filter.type]!=='undefined'"
                      :class="{active:typeof selectedFilters[filter.type]!=='undefined'&& (typeof selectedFilters[filter.type][filter.id][child_item.id]!=='undefined' || typeof selectedFilters[filter.type][filter.id][filter_item.id]!=='undefined')}"
                  >
                    <div class="filter-check mb-0">
                      <label class="form-check-label widget-filter-item-text" :for="filter.id+'_'+child_item.id">
                        <input class="form-check-input" type="checkbox" :id="filter.id+'_'+child_item.id"
                               @change="childChange($event,filter.type,filter.id,filter_item.id,key,child_key)"
                               :checked="
                               typeof selectedFilters[filter.type][filter.id][filter_item.id]!=='undefined' ||
                               typeof selectedFilters[filter.type][filter.id][child_item.id]!=='undefined'
    "
                        >
                        {{ child_item.name }}
                      </label>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </template>
          <template v-else>
            <h3 class="filter-group-title" @click="openFilter(filter.id)">{{filter.name}}</h3>
            <div class="filter-group-items">
              <ul class="inner-wrp custom-scrollbar">
                <li v-for="(filter_item,key) in filter.filter_items" class="widget-filter-item"
                    v-if="typeof selectedFilters[filter.type]!=='undefined'"
                    :class="{active:typeof selectedFilters[filter.type]!=='undefined'&& typeof selectedFilters[filter.type][filter.id][filter_item.id]!=='undefined'&&typeof selectedFilters[filter.type][filter.id][filter_item.id]==='1'}"
                >
                  <div class="filter-check mb-0">
                    <label class="form-check-label widget-filter-item-text" :for="filter.id+'_'+filter_item.id">
                      <input class="form-check-input" type="checkbox" :id="filter.id+'_'+filter_item.id"
                             @change="filterChange($event,filter.type,filter.id,filter_item.id,key)"
                             :checked="typeof selectedFilters[filter.type][filter.id][filter_item.id]!=='undefined'"
                      >
                      {{ filter_item.name }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>

      <div class="filters-summary" v-if="!loading">
        <template v-for="(sFilters,type) in selectedFilters">
          <template v-for="filter_index in Object.keys(sFilters)">
            <button v-for="value_index in Object.keys(sFilters[filter_index])"
                    class="btn btn-filter btn-sm remove_filter_value"
                    v-if="typeof filters[filter_index] !== 'undefined'"
                    v-show="sFilters[filter_index][value_index] !== null && (typeof filters[filter_index].filter_items[sFilters[filter_index][value_index]] !== 'undefined' || sFilters[filter_index][value_index].includes('_'))"
                    @click="clearValue(type,filter_index,value_index)"
            >
              <template v-if="typeof filters[filter_index].filter_items[sFilters[filter_index][value_index]] !== 'undefined'">
                <span>{{ filters[filter_index].filter_items[sFilters[filter_index][value_index]].name }}</span>
              </template>
              <template v-else>
                <span> {{ filters[filter_index].filter_items[sFilters[filter_index][value_index].split('_')[0]].childs[sFilters[filter_index][value_index].split('_')[1]].name }}</span>
              </template>
               <i class="fa-regular fa-xmark"></i>
            </button>
          </template>
        </template>
        <button class="btn btn-filter btn-link clean_all" :title="t('Atstatyti filtrus')" @click="clearAll()"
                v-show="filtersCount > 0">
          {{t('Atstatyti filtrus')}}
        </button>
      </div>

      <div class="filter-footer">
        <div>
          <button class="btn btn-outline-secondary clean-all" @click="clearAll()" v-show="filtersCount > 0">
            {{t('Išvalyti viską')}}
          </button>
        </div>

        <button class="btn btn-primary act-submit-filter" @click="submitFiltersMobile()">
          <i class="fa-solid fa-magnifying-glass me-2"></i>
          {{ t('Ieškoti') }}
        </button>
      </div>
    </div>

    <div class="sort-block">
      <div class="sort-wrp">
        <select id="sorting" class="selectpicker" :title="t('Rikiuoti pagal')" v-model="sortField">
          <option v-for="(name,value) in sortOptions" :value="value">{{name}}</option>
        </select>
      </div>
      <div class="total-offers">
        <div class="loader" v-show="loading">
          <span class="loader-placeholder" v-show="loading && productCount === 0"></span>
          <span class="loader-small-circle"></span>
        </div>
        <span class="result" v-show="productCount > 0">
          {{productCount}} {{str_by_number(productCount,[t('pasiūlymas'), t('pasiūlymai'), t('pasiūlymų')])}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {selectedFilters} from './reactives/selectedFilters';
import {sort} from './reactives/sort';

export default {
  props: ['title', 'filters', 'productCount', 'loading'],
  data() {
    return {
      selectedFilters,
      showFilters: false,
      openedFilter: 0,
      currentFilterState: false,
      sortOptions: window.sortOptions,
      sort,
      sortField: 'position desc',
      categoryOpened: null,
    }
  },
  watch: {
    sortField(newValue) {
      let arr = newValue.split(" ");
      this.sort.text = newValue;
      this.sort.field = arr[0];
      this.sort.direction = arr[1];
    }
  },
  computed: {
    filtersCount() {
      let count = 0;
      let component = this;
      Object.keys(component.selectedFilters).forEach(function (type) {
        Object.keys(component.selectedFilters[type]).forEach(function (key) {
          count = count + Object.keys(component.selectedFilters[type][key]).length;
        });
      });
      return count;
    }
  },
  mounted() {
    if (typeof window.selectedSort !== 'undefined' &&  window.selectedSort.length > 0) {
      this.sortField = window.selectedSort;
    }
  },
  methods: {
    toggleShowChildren(id) {
      if(this.categoryOpened === id) {
        this.categoryOpened = null;
      } else {
        this.categoryOpened = id;
      }
    },
    submitFiltersMobile() {
      this.submitFilters();
      this.toggleFilters();
    },
    categoryChange(event,type,id,value,key) {
      let component = this;
      let childs =  Object.keys(this.filters[id].filter_items[key]['childs']);
      if(typeof this.selectedFilters[type][id][value] !== 'undefined') {
        delete this.selectedFilters[type][id][value];
      } else {
        let intersection = Object.keys(component.selectedFilters[type][id]).filter(value => childs.includes(value))
        if (intersection.length > 0) {
          intersection.forEach(function (child_id) {
            delete component.selectedFilters[type][id][child_id];
          });
        }
        component.selectedFilters[type][id][value] = key;
      }
      this.submitFilters();
    },
    childChange(event,type,id,value,key,child_key) {
      let component = this;
      let childs =  Object.keys(this.filters[id].filter_items[key]['childs']);
      if(typeof component.selectedFilters[type][id][child_key] !== 'undefined') {
        delete component.selectedFilters[type][id][child_key];
      } else {
        if (typeof component.selectedFilters[type][id][value] !== 'undefined') {
          delete component.selectedFilters[type][id][value];
          childs.forEach(function (child) {
            if (child !== child_key) {
              component.selectedFilters[type][id][child] = key+'_'+child;
            }
          });
        } else {
          component.selectedFilters[type][id][child_key] = key+'_'+child_key;
          let intersection = Object.keys(component.selectedFilters[type][id]).filter(value => childs.includes(value))
          if (intersection.length === childs.length) {
            intersection.forEach(function (child_id) {
              delete component.selectedFilters[type][id][child_id];
            });
            component.selectedFilters[type][id][value] = key;
          }
        }
      }
      this.submitFilters();
    },
    filterChange(event,type,id,value,key) {
      if(typeof this.selectedFilters[type][id][value] !== 'undefined') {
        delete this.selectedFilters[type][id][value];
      } else {
        this.selectedFilters[type][id][value] = key;
      }
      this.submitFilters();
    },
    selectFilterItem() {
      //this.$emit('filter:selected', selectedFilters);
    },
    toggleFilters() {
      if (this.showFilters) {
        document.querySelector('.filter-backdrop').remove();
        document.querySelector('body').style.overflow = '';
        document.querySelector('body').style.paddingRight = '';
      } else {
        let backdrop = document.createElement('div');
        backdrop.classList.add('filter-backdrop');
        document.querySelector('body').appendChild(backdrop);
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector('body').style.paddingRight = '0px';
      }
      this.showFilters = !this.showFilters;
    },
    clearAll() {
      let component = this;
      Object.keys(component.selectedFilters).forEach(function (type) {
            Object.keys(component.selectedFilters[type]).forEach(function (key) {
              Object.keys(component.selectedFilters[type][key]).forEach(function (value) {
                delete component.selectedFilters[type][key][value];
              });
            });
      });
      this.submitFilters();
    },
    clearValue(type, filter, value) {
      delete this.selectedFilters[type][filter][value];
      this.submitFilters();
    },
    submitFilters() {
      this.$emit('filters:submit');
    },
    openFilter(id) {
      this.currentFilterState = JSON.parse(JSON.stringify(this.selectedFilters));
      if (this.openedFilter === id) {
        this.closeFilter(id);
      } else {
        this.openedFilter = id;
      }
    },
    closeFilter(id) {
      if (this.openedFilter === id) {
        // if (JSON.stringify(this.currentFilterState) !== JSON.stringify(this.selectedFilters)) {
        //   this.submitFilters();
        // }
        this.openedFilter = 0;
      }
    }
  }
}
</script>
